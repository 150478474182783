$(document).ready( function () {

	initCottageBooking();
	initRectoryBooking();


	// Open/close booking bar buttons
	$('.toggle-booking').on('click', function () {
		$('.booking-bar').css('display', 'block').addClass('open');
	});

	$('.booking-bar-close').on('click', function () {
		$('.booking-bar').removeClass('open');
		setTimeout(function () {
			$('.booking-bar').css('display', 'none');
		}, 500);
	});



	// Set the right Synxis parameters and other booking links depending on the hotel
	if ($('body').hasClass('hartwell-house')) { // Hartwell
		$('#synxis-hotel').val('15603');
		$('#synxis-chain').val('6386');
		$('#synxis-modify-cancel').attr('href', 'https://be.synxis.com/signin?_ga=&chain=6386&hotel=15603');

		$('#booking-bar-open-table').attr('href', 'https://7723fded-c4a4-4605-b717-6a890ecd2c71.resdiary.com/widget/FixedSize/HartwellHouse/696');
		$('#booking-bar-open-table-spa-cafe').attr('href', 'https://7723fded-c4a4-4605-b717-6a890ecd2c71.resdiary.com/widget/FixedSize/HartwellCafe/697');

		$('#booking-bar-open-spa').attr('href', '/the-spa/book-online/');

	} else if ($('body').hasClass('bodysgallen-hall')) { // Bodysgallen
		$('#synxis-hotel, .cottage-hotel-synxis').val('15580');
		$('#synxis-chain, .cottage-chain-synxis').val('6386');
		$('#synxis-modify-cancel').attr('href', 'https://be.synxis.com/?hotel=15580&Chain=6386&start=searchres');

		$('#booking-bar-open-table').attr('href', 'https://7723fded-c4a4-4605-b717-6a890ecd2c71.resdiary.com/widget/FixedSize/BodysgallenHall/698');

		$('#booking-bar-open-spa').attr('href', '/the-spa/book-online/');

	} else if ($('body').hasClass('middlethorpe-hall')) { // Middlethorpe
		$('#synxis-hotel').val('15057');
		$('#synxis-chain').val('6386');
		$('#synxis-modify-cancel').attr('href', 'https://be.synxis.com/?Hotel=15057&Chain=6386&template=RBE&shell=RBE&adult=2');


		//$('#booking-bar-open-table').attr('href', 'https://bookingengine.hardens.com/book/v2?restId=UKR_9959&keepThis=true&');
		//$('#booking-bar-open-table').remove();
		$('#booking-bar-open-table').attr('href', 'https://7723fded-c4a4-4605-b717-6a890ecd2c71.resdiary.com/widget/FixedSize/MiddlethorpeHallSpa/4853');

		$('#booking-bar-open-spa').attr('href', '/the-spa/book-online/');

	}


	// Implementation of linked check in and out fields with disabled past dates
	var nowTemp = new Date();
	var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);

	var checkin = $('#check-in').fdatepicker({
		format: 'dd/mm/yyyy',
		onRender: function (date) {
			return date.valueOf() < now.valueOf() ? 'disabled' : '';
		}
	}).on('changeDate', function (ev) {
		if (ev.date.valueOf() > checkout.date.valueOf()) {
			var newDate = new Date(ev.date)
			newDate.setDate(newDate.getDate() + 1);
			checkout.update(newDate);
		}
		checkin.hide();
		$('#check-out')[0].focus();
	}).data('datepicker');

	var checkout = $('#check-out').fdatepicker({
		format: 'dd/mm/yyyy',
		onRender: function (date) {
			return date.valueOf() <= checkin.date.valueOf() ? 'disabled' : '';
		}
	}).on('changeDate', function (ev) {
		checkout.hide();
	}).data('datepicker');



	// Widget changing
	$('.booking-bar-selector select').on('change', function () {
		changeBookingBar($(this).val());
	});



	// Show the right widget depending on the current section
	if (window.location.href.indexOf('/wine-dine/') > -1) {
		changeBookingBar('book-table');
	} else if (window.location.href.indexOf('/the-spa/') > -1) {
		changeBookingBar('book-treatment');
	}


	// Restaurant booking popups
	$('#booking-bar-open-table, a[href*="resdiary.com/widget"]').not('a[href*="bookingengine.hardens.com"]').fancybox({
		type: 'iframe',
		width: 320,
		height: 520,
		padding: [35, 14, 4, 14]
	});

	$('#booking-bar-open-table, a[href*="bookingengine.hardens.com"]').not('a[href*="resdiary.com/widget"]').fancybox({
		type: 'iframe',
		width: 360,
		height: 520,
		padding: [35, 0, 4, 0]
	});

	// Ensure the widget always fits within the screen, especially on mobile
	$(window).on('resize', function () {
		$('.booking-bar').css('max-height', window.innerHeight);
	});




	// Check for BookingBug widget on page and add the rotate prompt
	if ( $('iframe[src*="bookingbug.com"], script[src*="bookingbug.com"]').length > 0 ) {
		$('iframe[src*="bookingbug.com"], script[src*="bookingbug.com"]').first().before('<div class="bookingbug-rotate-device-icon"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 96.2 96.6" style="enable-background:new 0 0 96.2 96.6;" xml:space="preserve"><g><path class="st0" d="M66,10.1c13.3,6,22.5,18.9,24.2,34.2h6C93.8,19.3,73.3,0,47.9,0h-2.8l15.3,15.3L66,10.1z M40.6,6.8 c-2.4-2.4-6-2.4-8.5,0L6.4,32.6c-2.4,2.4-2.4,6,0,8.5l48.3,48.3c2.4,2.4,6,2.4,8.5,0L89,63.6c2.4-2.4,2.4-6,0-8.5L40.6,6.8z M59.2,85.3L10.9,37l25.8-25.8l48.3,48.3L59.2,85.3z M29.8,86.5c-13.3-6-22.5-18.9-24.2-34.2H0c2,25,22.5,44.3,47.9,44.3h2.8 L35.4,81.3L29.8,86.5z M29.8,86.5"/></g></svg></div><div class="bookingbug-rotate-device-label">Please rotate to landscape format</div>');
		$('.detail-content-left').addClass('text-center');
	}


	if ( $('body').hasClass('middlethorpe-hall')) {
		$('.booking-avail-results').remove();
	}

	if ( $('body').hasClass('bodysgallen-hall')) {
		$('.booking-avail-results').remove();
	}

	if ( $('body').hasClass('hartwell-house')) {
		$('.booking-avail-results').remove();
	}




});

function changeBookingBar(bookingType) {

	switch (bookingType) {
		case 'book-table':
			$('.booking-bar-selector select').val(bookingType);
			$('.booking-bar-table').removeClass('booking-bar-hidden');
			$('.booking-bar-table-spa-cafe, .booking-bar-synxis, .booking-bar-treatment, .booking-bar-why-book').addClass('booking-bar-hidden');
			break;
		case 'book-table-spa-cafe':
			$('.booking-bar-selector select').val(bookingType);
			$('.booking-bar-table-spa-cafe').removeClass('booking-bar-hidden');
			$('.booking-bar-table, .booking-bar-synxis, .booking-bar-treatment, .booking-bar-why-book').addClass('booking-bar-hidden');
			break;
		case 'book-treatment':
			$('.booking-bar-selector select').val(bookingType);
			$('.booking-bar-treatment').removeClass('booking-bar-hidden');
			$('.booking-bar-table, .booking-bar-table-spa-cafe, .booking-bar-synxis, .booking-bar-why-book').addClass('booking-bar-hidden');
			break;
		default:
			// Assume rooms
			$('.booking-bar-selector select').val('book-room');
			$('.booking-bar-synxis, .booking-bar-why-book').removeClass('booking-bar-hidden');
			$('.booking-bar-table, .booking-bar-table-spa-cafe, .booking-bar-treatment').addClass('booking-bar-hidden');
			break;
	}

}






function initCottageBooking() {
	$('a[href="#book-cottage"]').on('click', function () {
		$('#cottage-booking').foundation('open');
	});

	var nowTemp = new Date();
  	var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);

	$('#arrive').fdatepicker({
		format: 'dd-mm-yyyy',
		initialDate: new Date(),
    	minDate: new Date(),
		disableDblClickSelection: true,
		leftArrow:'<<',
		rightArrow:'>>',
		onRender: function (date) {
			return date.valueOf() < now.valueOf() ? 'disabled' : '';
		}
	});
}


function initRectoryBooking() {
	$('a[href="#book-rectory"]').on('click', function () {
		$('#old-rectory-booking').foundation('open');
	});

	var nowTemp = new Date();
  	var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);

	$('#arrive').fdatepicker({
		format: 'dd-mm-yyyy',
		initialDate: new Date(),
    	minDate: new Date(),
		disableDblClickSelection: true,
		leftArrow:'<<',
		rightArrow:'>>',
		onRender: function (date) {
			return date.valueOf() < now.valueOf() ? 'disabled' : '';
		}
	});

	$('#depart').fdatepicker({
		format: 'dd-mm-yyyy',
		initialDate: new Date(),
    	minDate: new Date(),
		disableDblClickSelection: true,
		leftArrow:'<<',
		rightArrow:'>>',
		onRender: function (date) {
			return date.valueOf() < now.valueOf() ? 'disabled' : '';
		}
	});


}
